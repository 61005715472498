import React, { useEffect, useState } from "react";
import "./qa_style.css";
import { 
    Button, 
    Checkbox, 
    Container, 
    FormControlLabel, 
    Grid, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TextField, 
    Typography, 
    CircularProgress, // Import CircularProgress
    Box // Import Box for positioning the loader
} from "@mui/material";
import { debounce } from "lodash";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import { toast } from "react-toastify";

// Interface for a checklist item
interface ChecklistItem {
    chassisPrepItemName: string;
    specification: string;
    notes: string;
    quantity: string;
    status: boolean | null;
}

const QualityAssurance = () => {
    const axiosPrivate = useAxiosPrivate();
    
    // Initial checklist state is empty
    const [checklist, setChecklist] = useState<ChecklistItem[]>([]);
    const [editableIndex, setEditableIndex] = useState<number | null>(null);
    // State for loading
    const [loading, setLoading] = useState<boolean>(false);
    const [chassisNo, setChassisNo] = useState<string>("");
    const [model, setModel] = useState<string>("");
    const [checkedBy, setCheckedBy] = useState<string>("");
    const [date, setDate] = useState<string>("");


    const handleChange = (index: number, field: "status") => {
        const updatedChecklist = [...checklist];
        updatedChecklist[index][field] = !updatedChecklist[index][field]; 
        setChecklist(updatedChecklist); 
    };

    const getData = async () => {
        try {
            const response = await axiosPrivate.get("get_chassis_prep");
            const data = response.data;
            console.log("data - ", data)
            const newChecklist = data.chassisPrepItems.map((item: any) => ({
                chassisPrepItemName: item.chassisPrepItemName || "",
                specification: item.specification || "",
                notes: item.notes || "",
                quantity: item.quantity || "",
                status: item.status || false,
            }));
            setModel(data.model || "");
            setCheckedBy(data.checkedBy || ""); 
            const formattedDate = data.createdOn ? new Date(data.createdOn).toLocaleDateString('en-CA', 
                {
                    year: 'numeric',
                     month: '2-digit',
                      day: '2-digit',
                }) : '';
            setDate(formattedDate || "");
            console.log( data.createdOn)
            setChecklist(newChecklist);
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleSearch = debounce(async (value: string) => {
        setLoading(true);
        console.log("value- ", value);

        try {
            if (!value) { 
                await getData()
            } else {
                setChassisNo(value)
                // Call the original API when value is not null or empty
                const response = await axiosPrivate.post(`prepare_chassis_prep/${value}`);
                const data = response.data;
                const newChecklist = data.chassisPrepItems.map((item: any) => ({
                    chassisPrepItemName: item.chassisPrepItemName || "",
                    specification: item.specification || "",
                    notes: item.notes || "",
                    quantity: item.quantity || "",
                    status: item.status || false,
                }));
                setModel(data.model || "");
                setCheckedBy(data.checkedBy || ""); 
                const formattedDate = data.createdOn ? new Date(data.createdOn).toLocaleDateString('en-CA', 
                {
                    year: 'numeric',
                     month: '2-digit',
                      day: '2-digit',
                }) : '';
                setDate(formattedDate || "");
                console.log(data.createdOn);
                setChecklist(newChecklist);
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false);
        }
    }, 500);


     const handleCheckedByChange = (event: any) => {
        setCheckedBy(event.target.value); 
    };

    const saveData = async () => {
        setLoading(true); // Show the loader while saving data
        try {
            const payload = {
                chassisNo: chassisNo,
                model,
                chassisPrepItems: checklist.map((item) => ({
                    chassisPrepItemName: item.chassisPrepItemName,
                    specification: item.specification,
                    notes: item.notes,
                    quantity: item.quantity,
                    status: item.status,
                })),
                checkedBy: checkedBy,
                createdOn: date
            };

            // const response = await axiosPrivate.post("create_qa_chassis_prep", payload);
            // if (response.status === 200) {
            //     alert("Data saved successfully!");
            // } else {
            //     alert("");
            // }
            await toast.promise(
                axiosPrivate.post("create_or_update_qa_chassis_prep", payload),
                {
                    pending: "Request pending",
                }
            );
            toast.success("Request Success..! 👌");
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Failed to save data.");
        } finally {
            setLoading(false); // Hide the loader after saving
        }
    };

    const handleNoteChange = (index: number, value: string) => {
        const updatedChecklist = [...checklist];
        updatedChecklist[index].notes = value; // Update the specific note
        setChecklist(updatedChecklist); // Update state
    };

    const handleCellClick = (index: number) => {
        setEditableIndex(index); // Set the clicked row as editable
    };

    const handleBlur = () => {
        setEditableIndex(null); // Reset the editable state on blur
    };

    const handleKeyPress = (event: React.KeyboardEvent, index: number) => {
        if (event.key === "Enter") {
            setEditableIndex(null); // Save changes and exit edit mode on Enter
        }
    };



    return (
        <Container sx={{ mt: 2, mb: 3, bgcolor: 'background.default' }}>
            <Typography variant="h4" gutterBottom className="mb-4">Chassis Prep - Electrical</Typography>
            
            {/* Loader */}
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Grid container spacing={2} mb={4}>
                <Grid item md={3}>
                    <TextField 
                        label="DC No" 
                        variant="filled" 
                        size="small"
                        sx={{
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                color: '#1976d2',
                            },
                            '& .MuiInputBase-input': {
                                color: '#000000',
                                fontSize: '20px'
                            },
                        }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField 
                        disabled 
                        label="Model" 
                        variant="filled" 
                        value={model}
                        size="small"
                        sx={{
                            '& .MuiInputLabel-root': {
                                fontWeight: 'bold',
                                color: '#1976d2 !important'
                            },
                            '& .MuiInputBase-input': {
                                color: '#1976d2 !important',
                                opacity: '1 !important',
                                '&[disabled]': {
                                    color: 'rgba(25, 118, 210, 1) !important',
                                },
                                fontSize: '20px'
                            },
                        }} 
                    />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Grid item md={12}>
                    {/* Table with blur effect when loading */}
                    <TableContainer 
                        sx={{
                            position: 'relative', 
                            filter: loading ? 'blur(5px)' : 'none',  // Apply blur when loading
                            pointerEvents: loading ? 'none' : 'auto'  // Disable interactions during loading
                        }}
                    >
                        <Table aria-label="chassis prep checklist">
                            <TableHead>
                                <TableRow className="table__head_row">
                                    <TableCell className="table__head_cell" sx={{ width: '23%' }}>CHASSIS PREP ITEM</TableCell>
                                    <TableCell className="table__head_cell">SPECIFICATION</TableCell>
                                    <TableCell className="table__head_cell" sx={{ width: '15%' }}>NOTES</TableCell>
                                    <TableCell className="table__head_cell" sx={{ width: '5%' }}>QTY</TableCell>
                                    <TableCell className="table__head_cell" sx={{ width: '5%' }}>STATUS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checklist.map((item, index) => (
                                    <TableRow key={index} style={{ padding: '0px' }}>
                                        <TableCell className="p-0 px-2" sx={{ borderLeft: '1px solid #d0d0d0' }}>{item.chassisPrepItemName}</TableCell>
                                        <TableCell className="p-0 px-2" sx={{ borderLeft: '1px solid #d0d0d0' }}>{item.specification}</TableCell>
                                        <TableCell onClick={() => handleCellClick(index)} sx={{ cursor: "pointer" }}>
                                            {editableIndex === index ? (
                                                <TextField
                                                    value={item.notes}
                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                    onBlur={handleBlur}
                                                    onKeyPress={(e) => handleKeyPress(e, index)}
                                                    variant="outlined"
                                                    size="small"
                                                    autoFocus
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        color: "text.primary",
                                                        "&:hover": { color: "primary.main", textDecoration: "underline" },
                                                    }}
                                                >
                                                    {item.notes || ""}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell className="p-0 px-2" sx={{ borderLeft: '1px solid #d0d0d0' }}>{item.quantity}</TableCell>
                                        <TableCell className="p-0 px-2" sx={{ borderLeft: '1px solid #d0d0d0', textAlign: 'center' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.status === true}
                                                        onChange={() => handleChange(index, 'status')}
                                                        sx={{ margin: 0, padding: '3px' }}
                                                    />
                                                }
                                                label=""
                                                sx={{ margin: 0 }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid container spacing={3} my={4}>
                <Grid item md={4}>
                    <TextField
                        label="Checked by:"
                        variant="outlined"
                        value={checkedBy}
                        onChange={handleCheckedByChange}
                        fullWidth
                    />
                </Grid>
                <Grid item md={4}>
                    <TextField
                        label="Date:"
                        variant="outlined"
                        type="date"
                        value={date}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>

            <Button
                variant="contained"
                color="primary"
                onClick={saveData}
                disabled={loading} // Disable the button while saving
            >
                Save
            </Button>

        </Container>
    );
};

export default QualityAssurance;


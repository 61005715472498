// import _ from "lodash";
// import { useEffect, useState } from "react";

// const CycleTimer = ({ line }: any) => {
//     const [timeRemaining, setTimeRemaining] = useState(
//         parseInt(localStorage.getItem(`${line}_timeRemaining`) || "0", 10)
//     );
//     const [currentCycle, setCurrentCycle] = useState(
//         parseInt(localStorage.getItem(`${line}_currentCycle`) || "1", 10)
//     );

//     useEffect(() => {
//         let lineName: any = _.first(line.toLowerCase().split(" "));

//         const socket = new WebSocket(`ws://localhost:8000/ws/timer/${lineName}`);
//         console.log(socket);
//         socket.addEventListener('open', (event) => {
//             console.log('WebSocket is connected:', event);
//         });

//         socket.onmessage = (event) => {
//             const data = JSON.parse(event.data);
//             console.log(data); // Check the data structure here

//             if (data) {
//                 const remainingTime = parseInt(data.time_remaining.split(":")[0]) * 60 + parseInt(data.time_remaining.split(":")[1]);
//                 setTimeRemaining(remainingTime);
//                 setCurrentCycle(data.current_cycle);

//                 // Save data to local storage to persist across refresh
//                 localStorage.setItem(`${line}_timeRemaining`, remainingTime.toString());
//                 localStorage.setItem(`${line}_currentCycle`, data.current_cycle.toString());
//             }
//         };

//         socket.onclose = () => {
//             console.log("WebSocket connection closed");
//             // Attempt to reconnect
//             setTimeout(() => {
//                 console.log("Reconnecting WebSocket...");
//                 const newSocket = new WebSocket(`ws://localhost:8000/ws/timer/${lineName}`);
//                 newSocket.onmessage = socket.onmessage;
//             }, 1000); // Reconnect after 1 second
//         };

//         return () => {
//             socket.close();
//         };
//     }, [line]);

//     useEffect(() => {
//         // Set up a local timer to decrement `timeRemaining` every second
//         const interval = setInterval(() => {
//             setTimeRemaining((prevTime) => {
//                 if (prevTime > 0) {
//                     const newTime = prevTime - 1;

//                     // Save updated time to local storage
//                     localStorage.setItem(`${line}_timeRemaining`, newTime.toString());
//                     return newTime;
//                 }
//                 return 0;
//             });
//         }, 1000);

//         return () => clearInterval(interval); // Cleanup interval on component unmount
//     }, [timeRemaining]); // Only rerun if timeRemaining changes

//     const formatTime = (seconds: number) => {
//         if (isNaN(seconds) || seconds < 0) {
//             return "00:00";
//         }
//         const minutes = Math.floor(seconds / 60);
//         const secondsLeft = seconds % 60;
//         return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
//     };

//     return (
//         <span style={{ fontSize: '1.2vmin', fontWeight: 'normal' }}>
//             {formatTime(timeRemaining)} (Cycle {currentCycle}/{8})
//         </span>
//     );
// };

// export default CycleTimer;


import React, { useEffect, useState } from 'react';
const { REACT_APP_API_WEBSOCKET_URL } = process.env;

const CycleTimer = ({ line }: { line: string }) => {
    const [count, setCount] = useState<number | null>(null);
    const [currentRepeat, setCurrentRepeat] = useState<number>(0);
    const [totalRepeat, setTotalRepeat] = useState<number>(0);
    
    useEffect(() => {
        // const ws = new WebSocket(`ws://${REACT_APP_API_BASE_URL_TWO}/ws/countdown/${line}`);
        const ws = new WebSocket(`${REACT_APP_API_WEBSOCKET_URL}/ws/countdown/${line}`);
        ws.onmessage = (event) => {
            // console.log(event.data)
            const data = JSON.parse(event.data);
            setCount(data.count);
            setCurrentRepeat(data.current_repeat);
            setTotalRepeat(data.total_repeats);
        };
    }, [line]);

    const formatTime = (seconds: number) => {
        if (isNaN(seconds) || seconds < 0) {
            return "00:00";
        }
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
    };

    return (
        <div style={{ textAlign: 'center', fontSize: '1.2vmin' }}>
            {count !== null ? (
                <div>
                    {formatTime(count)} (Cycle {currentRepeat ?? 0}/{totalRepeat ?? 0})
                </div>
            ) : (
                <div>00:00 (Cycle 0/0)</div>
            )}
        </div>
    );
};

export default CycleTimer;


import React, { useState } from "react";
import _ from "lodash";

const { REACT_APP_API_WEBSOCKET_URL } = process.env;

export default function Timer({ selectedLineValue }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [count, setCount] = useState(null);

    React.useEffect(() => {
      console.log(selectedLineValue);
      const line = _.first(selectedLineValue.split("-"));
      const ws = new WebSocket(`${REACT_APP_API_WEBSOCKET_URL}/ws/countdown/${line}`);
      ws.onmessage = (event) => {
          // console.log(event.data)
          const data = JSON.parse(event.data);
          setCount(data.count);
          // setCurrentRepeat(data.current_repeat);
          // setTotalRepeat(data.total_repeats);
      };

        const intervalId = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
        
      }, []);
    
    const formatTime = (seconds) => {
        if (isNaN(seconds) || seconds < 0) {
            return "00:00";
        }
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;
        return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`;
    };
    
    return (
        <div className="d-flex gap-2 px-2 date">
        <p className="m-0 font-lg fw-medium">Date: </p>
        <div className="d-flex gap-4">
          <p className="m-0 font-lg fw-medium">
            {currentTime.toLocaleDateString("en-GB")}
          </p>
          <p className="m-0 font-lg fw-medium">
            {/* {currentTime.toLocaleTimeString()} */}
            {formatTime(count)}
          </p>
        </div>
      </div>
    );
}
import React from "react";
import { toast } from "react-toastify";
import "./form-style.css";
import useAxiosPrivate from "../../common/useAxiosPrivate";

const ProductionScheduleSetting = () => {
  const [mainLineData, setMainLineData] = React.useState<any>({});
  const [crvData, setCrvData] = React.useState<any>({});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const axiosPrivate = useAxiosPrivate();

  const fieldConfig: { [key: string]: { label: string; type: string } } = {
    weeklyProductionTarget: {
      label: "Production target per week: ",
      type: "static", // Marked as static for display purposes
    },
    startDate: { label: "Start Date", type: "date" },
    monday: { label: "Monday", type: "number" },
    tuesday: { label: "Tuesday", type: "number" },
    wednesday: { label: "Wednesday", type: "number" },
    thursday: { label: "Thursday", type: "number" },
    friday: { label: "Friday", type: "number" }
  };

  const fetchProductionScheduleSetting = async () => {
    try {
      const response = await axiosPrivate.get(
        `get_all_production_schedule_settings`
      );
      const data = response.data;

      const mainLine = data.find((item: any) => item.line === "main") || {};
      const crvLine = data.find((item: any) => item.line === "crv") || {};

      setMainLineData(mainLine);
      setCrvData(crvLine);
    } catch (error) {
      console.error("Error fetching production settings:", error);
    }
  };

  const handleChange = (
    line: "main" | "crv",
    key: string,
    value: string | number
  ) => {
    if (line === "main") {
      setMainLineData((prev: any) => ({ ...prev, [key]: value }));
    } else {
      setCrvData((prev: any) => ({ ...prev, [key]: value }));
    }

    // Validate input
    if (Number(value) < 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: `${fieldConfig[key]?.label || key} cannot be negative`,
      }));
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[key];
        return updatedErrors;
      });
    }
  };

  const submitData = async (event: React.FormEvent) => {
    event.preventDefault();

    toast.info("Saving data...", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      toastId: "requestPending",
    });

    try {
      await Promise.all([
        axiosPrivate.put(
          `update_production_schedule_setting/${mainLineData.id}`,
          mainLineData
        ),
        axiosPrivate.put(
          `update_production_schedule_setting/${crvData.id}`,
          crvData
        ),
      ]);

      toast.dismiss("requestPending");
      toast.success("Data saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error saving data:", error);
      toast.dismiss("requestPending");
      toast.error("Failed to save data.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  React.useEffect(() => {
    fetchProductionScheduleSetting();
  }, []);

  const renderForm = (
    data: any,
    setData: React.Dispatch<React.SetStateAction<any>>,
    lineName: string
  ) => (
    <div className="col-6">
      <div className="title_container text-center pb-3">
        <h2>{lineName} Line</h2>
      </div>
      <div className="row">
        {Object.entries(data)
          .filter(([key]) => key !== "id" && key !== "line") // Exclude "id" and "line"
          .map(([key, value]) => (
            <div className="col-12 input_field mb-3" key={key}>
              <label className="form-label fw-medium font-lg">
                {fieldConfig[key]?.label ||
                  key
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, (str) => str.toUpperCase())}
              </label>
              {key === "weeklyProductionTarget" ? (
                <h6 className="fw-bold">{String(value) || "-"}</h6>
              ) : key === "startDate" ? (
                <input
                  type="date"
                  className="form-control"
                  value={
                    value && typeof value === "string"
                      ? new Date(value).toISOString().slice(0, 10)
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      lineName === "Main" ? "main" : "crv",
                      key,
                      e.target.value
                    )
                  }
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  value={
                    typeof value === "string" || typeof value === "number"
                      ? value
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      lineName === "Main" ? "main" : "crv",
                      key,
                      e.target.value
                    )
                  }
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <div className="form_wrapper">
          <form onSubmit={submitData}>
            <div className="row">
              {renderForm(mainLineData, setMainLineData, "Main")}
              {renderForm(crvData, setCrvData, "CRV")}
            </div>
            <div className="col-6 mx-auto mt-4">
              <input className="btn blue-btn" type="submit" value="Save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductionScheduleSetting;

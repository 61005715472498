import { CellLocation, Id, ReactGrid } from "@silevis/reactgrid";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "@silevis/reactgrid/styles.css";
import "./style.css";
import ScheduleModal from "./ScheduleFeedModal/ScheduleModal";
import {
  ScheduledFeedPresenter,
  debounce,
  handleDragRow,
} from "./ScheduledFeed-presenter";
import { ResponseField } from "./ScheduledFeed-models";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { FeaturesPresenter } from "../featuresPresenter/featuresPresenter";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import _ from "lodash";

function ScheduleFeed() {
  const scheduledDataPresenter = new ScheduledFeedPresenter();
  const featuresDataPresenter = new FeaturesPresenter();
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const columns = scheduledDataPresenter.tableColumns;
  const [data, setData] = useState<ResponseField[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalSaveLoading, setModalSaveLoading] = useState(false);
  const [rows, setRows] = useState<any>();
  const [tabToggle, setTabToggle] = useState(1);
  const lineOptionsValue = featuresDataPresenter.getLineOptions();
  const [selectedOption, setSelectedOption] = useState("main-line");
  const [tableModalShow, setTableModalShow] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<CellLocation>();
  const [selectedCheckBoxIds, setSelectedCheckBoxIds] = useState<string[]>([]);
  const [rowLockState, setRowLockState] = useState<boolean>(false);
  const [isReorderTriggered, setIsReOrderTriggered] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const axiosPrivate = useAxiosPrivate();

  // async function loadLineData(isRefresh: boolean) {
  //   setLoading(true);
  //   try {
  //     const queryData = await scheduledDataPresenter.loadLineData(
  //       selectedOption,
  //       searchQuery,
  //       isRefresh
  //     );
  //     setData(queryData);
  //     if (searchQuery && queryData.length) {
  //       scheduledDataPresenter.scrollToFilteredRow(queryData);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  //   setLoading(false);
  // }
  // const debouncedMainLineDataLoad = debounce(() => loadLineData(false), 1000);

  const getAllScheduleData = async (line: string, search: string = "") => {
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `get_all_schedules_by_line/${line}?query=${search}`
      );
      const data = response.data;
      setData(data);
      setLoading(false);
    } catch (ex: any) {
      console.error(ex);
      setLoading(false);
    }
  };

  useEffect(() => {
    // debouncedMainLineDataLoad();
    getAllScheduleData(selectedOption == "main-line" ? "main" : "crv");
    console.log(searchQuery);
  }, [selectedOption]);

  useEffect(() => {
    setRows(scheduledDataPresenter.getRows(data));
  }, [data, selectedCheckBoxIds]);

  useEffect(() => {
    if (isReorderTriggered) {
      window.addEventListener("mousemove", handleDragRow, {
        capture: false,
        once: false,
        passive: true,
      });
    }
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleDragRow);
    };
  }, [isReorderTriggered]);

  const handleOpenTableModal = (location: CellLocation) => {
    setTableModalShow(true);
    setSelectedRow(location);
    const selectedIdsString =
      data[Number(location.rowId)]?.scheduleSubStatus?.split(",") ?? [];
    setSelectedCheckBoxIds(data[Number(location.rowId)]?.subStatuses);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idNumber = event.target.id.split("-")[1];
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCheckBoxIds((prevIds: any) => [...prevIds, idNumber]);
    } else {
      setSelectedCheckBoxIds((prevIds: any) =>
        prevIds.filter((id: any) => id !== idNumber)
      );
    }
    return "";
  };

  const handleChangeLockState = (lockVal: boolean) => {
    setRowLockState(lockVal);
  };

  const handleSaveTableModal = async (
    rowIndex: any,
    checkBoxIds: string[],
    textValues: any,
    selectedLine: string,
    reservationVal: string,
    hideModal: any
  ) => {
    const rowData = data[Number(rowIndex)];
    const fromChassisNo = data[Number(rowIndex)].serial;
    setModalSaveLoading(true);
    try {
      if (Object.values(textValues).some((value) => Boolean(value))) {
        const swapOptionsResponse =
          await scheduledDataPresenter.updateModalSwapOptions(
            textValues,
            rowIndex,
            selectedLine,
            fromChassisNo
          );
        if (swapOptionsResponse) setData(swapOptionsResponse);
      }
      const date = new Date(Date.now());
      const body = {
        id: rowData.id,
        status: rowData.status ?? "",
        subStatuses: rowData.status === "Scheduled" ? checkBoxIds : [],
        notes: rowData.notes ?? "",
        chassisNotes: rowData.chassisNotes ?? "",
        electricalSystem: rowData.electricalSystem ?? "",
        upgradePack: rowData.upgradePack ?? "",
        productionValue: rowData.productionValue ?? "",
        drawnBy: rowData.drawnBy ?? "",
        drawnDate: date.toISOString(),
        isLocked: rowLockState,
        reservationVal: reservationVal ?? "",
      };
      let line = selectedOption == "main-line" ? "main" : "crv";
      await axiosPrivate.put(`update_schedule_by_line/${line}`, body);

      // await scheduledDataPresenter.handleModalDataChange(
      //   rowData,
      //   checkBoxIds,
      //   selectedOption,
      //   reservationVal,
      //   rowLockState
      // );
      await getAllScheduleData(line);
      // setData(response);
      // setRows(scheduledDataPresenter.getRows(data));
      // data[Number(rowIndex)].reservationVal = reservationVal;
    } catch (error) {
      console.log("Update error", error);
    }
    setModalSaveLoading(false);
    setTableModalShow(false);
    hideModal();
  };

  const handleRowsReorder = async (targetRowId: Id, movingRowIds: Id[]) => {
    // console.log("tarfetRowId", targetRowId, "moving", movingRowIds);
    const target = Number(targetRowId);
    const movingRowChassisList = scheduledDataPresenter.getMovingRowChassisList(
      movingRowIds,
      data
    );
    const targetRowCompletionList =
      scheduledDataPresenter.getTargetRowCompletionDate(targetRowId, data);
    console.log(
      "moving",
      movingRowChassisList,
      "target",
      targetRowCompletionList
    );
    const reorderedRows = scheduledDataPresenter.getReorderedRows(
      target,
      movingRowIds,
      data
    );
    setIsReOrderTriggered(false);
    const isRowLocked = movingRowIds.some(
      (rowId) => data[Number(rowId)].isLocked
    );
    if (isRowLocked) {
      toast.error("Selected row is locked");
    } else {
      setLoading(true);
      try {
        const updatedData = scheduledDataPresenter.reorderDataRowAndSave(
          data,
          targetRowId,
          movingRowIds
        );
        let line = selectedOption == "main-line" ? "main" : "crv";
        const response = await axiosPrivate.post(
          `reorder_schedules_by_line/${line}`,
          updatedData
        );
        // console.log(updatedData);
        // const dbResponse = await scheduledDataPresenter.sendReorderedDataToDb(
        //   reorderedRows.oldRows,
        //   reorderedRows.newRows
        // );
        // setData(reorderedData);
        await getAllScheduleData(line);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("reordering error", error);
      }
    }
  };

  const handleCanReorderRows = (targetRowId: Id): boolean => {
    // console.log("target", targetRowId);
    setIsReOrderTriggered(true);
    return targetRowId !== "header";
  };

  const handleRearrange = async () => {
    // const responseData = await scheduledDataPresenter.handleRearrange(
    //   selectedOption
    // );
    // setData(responseData);
    await getAllScheduleData(selectedOption == "main-line" ? "main" : "crv");
  };

  const handleDeleteReservation = async (onHideModal: any) => {
    setModalSaveLoading(true);
    try {
      // const response = await scheduledDataPresenter.handleRemoveReserveItem(
      //   selectedOption,
      //   Number(selectedRow?.rowId)
      // );
      // setData(response);
      if (selectedRow?.rowId) {
        let line = selectedOption == "main-line" ? "main" : "crv";
        await axiosPrivate.delete(
          `delete_schedule_by_line/${
            data[Number(selectedRow?.rowId)].id
          }/${line}`
        );
        await getAllScheduleData(line);
        toast.success("Reserved Item Deleted Successfully");
      }
    } catch (error) {
      toast.error("Could Not Delete Reserved Item");
      console.log("Failed", error);
    }
    setModalSaveLoading(false);
    onHideModal();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const handleRefresh = async () => {
    // loadLineData(true);
    try {
      setLoading(true);
      let line = selectedOption == "main-line" ? "main" : "crv";
      const response = await axiosPrivate.post(
        `sync_all_schedules_with_cots_by_line`,
        { line: line }
      );
      getAllScheduleData(line);
      setLoading(false);
    } catch (ex: any) {
      console.error(ex);
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="myGrid"
        style={{
          width: `${!loading && data.length ? "max-content" : "100vw"}`,
        }}
      >
        <div
          className="position-sticky d-flex align-items-center bg-white z-3"
          style={{ top: "64px" }}
        >
          <div className="d-flex gap-3 align-items-center py-2">
            <h1 className="mb-2 fw-semibold">Crusader Production Schedule</h1>
            <select
              className="form-select"
              aria-label="Table Select"
              onChange={(event) => setSelectedOption(event.target.value)}
            >
              {lineOptionsValue.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.displayText}
                </option>
              ))}
            </select>
            <button className="btn btn-danger" onClick={handleRearrange}>
              Re-arrange
            </button>
            <div className="refresh-button">
              <button
                className="btn blue-btn fw-medium"
                onClick={handleRefresh}
              >
                Refresh
              </button>
            </div>
          </div>
          <div className="myGrid__search-box position-relative ms-5">
            <div className="input-group">
              <input
                type="text"
                className="rounded-sm form-control"
                placeholder="Free Text Search"
                value={searchQuery}
                onChange={handleSearch}
                aria-describedby="basic-addon2"
                style={{ paddingLeft: "10px", fontSize: "15px" }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    getAllScheduleData(
                      selectedOption == "main-line" ? "main" : "crv",
                      searchQuery
                    );
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="MagnifyingGlass">
                      <path
                        id="Vector"
                        d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                        stroke="#1D2026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M16.4431 16.4438L20.9994 21.0002"
                        stroke="#1D2026"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            {/* <input
              className="rounded-sm form-control"
              placeholder="Free Text Search"
              type="text"
              value={searchQuery}
              onChange={handleSearch}
            />
            <span className="myGrid__search-box__icon d-flex align-items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="MagnifyingGlass">
                  <path
                    id="Vector"
                    d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                    stroke="#1D2026"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M16.4431 16.4438L20.9994 21.0002"
                    stroke="#1D2026"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </span> */}
          </div>
        </div>
        <div className="tab d-flex gap-2 mb-3">
          <button
            className={`px-3 py-1 ${tabToggle === 1 ? "active" : ""}`}
            onClick={() => setTabToggle(1)}
          >
            Department 1
          </button>
          <button
            className={`px-3 py-1 ${tabToggle === 2 ? "active" : ""}`}
            onClick={() => setTabToggle(2)}
          >
            Department 2
          </button>
        </div>
        {loading && (
          <div className="loader-wrapper">
            <div className="loader-spinner">
              <BeatLoader
                loading={loading}
                color="#432a70"
                cssOverride={{
                  borderColor: "#432a70",
                  opacity: 1,
                }}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        )}
        {isBigScreen ? (
          <>
            <div
              className={`schedule-feed__data-grid ${
                tabToggle === 1 ? "d-block" : "d-none"
              }`}
            >
              {!data.length && !loading ? (
                <p>Data not available!</p>
              ) : (
                <div className="position-sticky">
                  <ReactGrid
                    stickyTopRows={1}
                    rows={rows ?? []}
                    columns={columns ?? []}
                    customCellTemplates={{
                      anchor: scheduledDataPresenter.AnchorCellTemplate,
                    }}
                    onFocusLocationChanged={handleOpenTableModal}
                    onRowsReordered={handleRowsReorder}
                    canReorderRows={handleCanReorderRows}
                    enableRowSelection
                  />
                </div>
              )}
            </div>
            <div className={tabToggle === 2 ? "d-block" : "d-none"}>
              <p>Tab 2</p>
            </div>
            {tableModalShow &&
              selectedRow &&
              selectedRow.columnId === "Edit" && (
                <ScheduleModal
                  show={tableModalShow}
                  onHide={() => setTableModalShow(false)}
                  handleSaveModal={handleSaveTableModal}
                  checkBoxIds={selectedCheckBoxIds}
                  handleCheckBoxChange={handleCheckboxChange}
                  rowData={rows}
                  tableData={data}
                  isSaving={modalSaveLoading}
                  rowindex={selectedRow.rowId}
                  selectedLine={selectedOption}
                  handleChangeLockState={handleChangeLockState}
                  handleDeleteReservation={handleDeleteReservation}
                ></ScheduleModal>
              )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default ScheduleFeed;
